import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="background">
        <header className="App-header">
          <h1 className="App-title">Website is in progress</h1>
          <p className="App-subtitle">We will be with you soon</p>
        </header>
      </div>
    </div>
  );
}

export default App;
